import React, { useState, useEffect } from "react"

import Logo from "../assets/images/logo.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  faCalendar as calendar,
  faPlay as play,
  faDownload as download,
} from "@fortawesome/free-solid-svg-icons"
import calandarsvg from "../assets/images/Vector.svg"
import downloadsvg from "../assets/images/Vector1.svg"
import { Helmet } from "react-helmet"
import "../modules/indexStyle/style.styl"
import useWindowSize from "../common/useWindowSize/useWindowSize"
import SEO from "../common/seo/seo"

export default function Index() {
  const [open, setOpen] = useState(false)

  const { width } = useWindowSize()

  const c_iframe_holder = {
    display: "none",
  }

  const iframe_holder = {
    display: "block",
    position: "absolute",
    width: "100vw",
    height: "100vh",
    display: "grid",
    placeItems: "center",
    boxSizing: "border-box",
    background: "rgba(0,0,0,0.6)",
  }

  const c_iframe = {
    display: "none",
  }

  const iframe = {
    display: "flex",
    position: "absolute",
    margin: 0,
  }

  const logo = {
    position: "absolute",
    top: "1rem",
    left: "0",
    right: "0",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    height: "2rem",
  }

  const wrapper = {
    display: "grid",
    placeItems: "center",
    height: "100vh",
    boxSizing: "border-box",
    fontFamily: "Helvetica, sans-serif",
    position: "relative",
    background: "#f9f9f9",
    padding: width > 768 ? "0 3rem" : "0",
  }

  const span = {
    color: "red",
  }

  const icon = {
    display: "grid",
    placeItems: "center",
    height: width > 460 ? "4rem" : "2rem",
    width: width > 460 ? "4rem" : "2rem",
    background:
      "linear-gradient(to bottom,rgba(255,255,255,1),rgba(255,255,255,0.25))",
    color: "red",
    borderRadius: "9999px",
    fontSize: width > 460 ? "2rem" : "1rem",
  }

  const link = {
    display: "flex",
    alignItems: "center",
    fontWeight: 400,
    fontSize: "1.2rem",
    color: "black",
    textDecoration: "none",
  }

  const linkHolder = {
    display: "flex",
    gap: width > 768 ? "6rem" : "1rem",
    marginTop: "10rem",
    justifyContent: "center",
    // flexDirection: width > 768 ? "row" : "column",
    // paddingLeft: width > 460 ? "0" : "2rem",
  }

  const heading = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }
  const h1 = {
    fontSize: width > 768 ? "4rem" : width > 460 ? "3rem" : "2rem",
    fontWeight: "500",
    textAlign: "center",
  }
  const playIcon = {
    fontSize: width > 460 ? "3rem" : "2rem",
    marginTop: "2rem",
    cursor: "pointer",
  }

  const headingHolder = {}

  const [xMain, setXmain] = useState(0)
  const [yMain, setYmain] = useState(0)
  const [xTrailing, setXT] = useState(0)
  const [yTrailing, setYT] = useState(0)

  const handleMouseMove = e => {
    const { clientX, clientY } = e

    setXmain(clientX)
    setYmain(clientY)

    setTimeout(() => {
      setXT(clientX)
      setYT(clientY)
    }, 100)
  }

  return (
    <>
      <div className={width < 768 ? "hidden_cursors" : "cursors"}>
        <div
          className={width < 768 ? "hidden_cursors" : "cursor"}
          style={{
            left: xMain,
            top: yMain,
          }}
        />
        <div
          className={width < 768 ? "hidden_cursors" : "cursor"}
          style={{
            left: xTrailing,
            top: yTrailing,
          }}
        />
      </div>
      <div
        style={wrapper}
        className="index_wrapper"
        onMouseMove={e => handleMouseMove(e)}
      >
        <SEO title="Home" />
        <Helmet>
          <script
            src="https://fast.wistia.net/assets/external/E-v1.js"
            async
          ></script>
        </Helmet>
        <div style={logo} className="index_logo">
          <img src={Logo} alt="hookpoint" />
        </div>
        <div
          style={headingHolder}
          className="index_headingHolder"
          onClick={e => {
            e.stopPropagation()
            setOpen(true)
            if (typeof window !== undefined) {
              window._wq.push({
                id: "_all",
                onReady: video => {
                  video.unmute()
                },
              })
            }
          }}
        >
          <div style={heading} className="index_heading">
            <h1 style={h1} className="index_h1">
              How do you win at an {width < 360 && <br />}
              <span style={span} className="index_span">
                unfair game?
              </span>
            </h1>
            <FontAwesomeIcon
              icon={play}
              style={playIcon}
              className="index_playIcon"
            />
          </div>
          <div className="index_linkWrapper">
            <div style={linkHolder} className="index_linkHolder">
              <div style={link} className="index_link">
                <a
                  style={link}
                  className="index_link"
                  href={"/download/"}
                  // download
                  // ref=""
                  target="_blank"
                  without
                  rel="noreferrer"
                  onClick={e => {
                    e.stopPropagation()
                  }}
                >
                  <div style={icon} className="index_icon">
                    <img src={downloadsvg} />
                  </div>
                  Download the deck
                </a>
              </div>
              <div style={link} className="index_link">
                <a
                  style={link}
                  className="index_link"
                  href="/apply"
                  target="_blank"
                  without
                  rel="noreferrer"
                  onClick={e => {
                    e.stopPropagation()
                  }}
                >
                  <div style={icon} className="index_icon">
                    {" "}
                    <img src={calandarsvg} />
                  </div>
                  Schedule a call
                </a>
              </div>
            </div>
          </div>
        </div>

        {open && (
          <div
            style={open ? iframe_holder : c_iframe_holder}
            onClick={e => {
              e.stopPropagation()
              setOpen(false)
            }}
          >
            <iframe
              src="https://fast.wistia.net/embed/iframe/bxtng7dwif"
              title="Hook Point video"
              allow="autoplay; fullscreen"
              allowtransparency="true"
              frameborder="0"
              scrolling="no"
              className="wistia_embed index_player"
              name="wistia_embed"
              id="wista_embed"
              allowfullscreen
              msallowfullscreen
              style={open ? iframe : c_iframe}
            ></iframe>
            <div></div>
          </div>
        )}
        <div
          style={{
            position: "absolute",
            bottom: "1rem",
            right: "1rem",
            color: "gray",
            display: "flex",
            alignItems: "center",
          }}
        >
          <hr
            style={{
              width: "5rem",
              borderBottom: "solid gray 1px",
              marginRight: "1rem",
            }}
          />
          <p>© 2021 Hook Point</p>
        </div>
      </div>
    </>
  )
}
